import React from 'react'
import medical_kit from '../../images/knowMore/medical_kit.png'

function Banner() {
  return (
    <>
        <section
            className="
            text-gray-800 w-full flex bg-resources_banner_bg
            items-center justify-center bg-blue-500"
            id="home"
        >
            <div
            className="
            flex flex-col justify-center p-2
            sm:py-10 lg:py-24 lg:px-8 lg:flex-row
            lg:justify-between pb-4 pt-10
            xl:ml-5 w-10/12 max-w-7xl mt-10
            "
            >
                <div
                    className="
                    flex flex-col justify-center
                    lg:max-w-xl xl:max-w-2xl lg:text-left
                    "
                >
                    <h1 className='sm:text-6xl text-5xl text-white font-bold mt-2'>
                        Quality Healthcare <br />
                        Made Simple <br />
                    </h1>

                    {/* <div className='bg-orange-500 w-28 h-1 mt-8' data-aos="fade-right"> </div> */}
                    <h2 className='text-orange-500 text-2xl font-bold'>About Kenecare</h2>
                    <p class="text-white mb-8 text-justify max-w-3xl text-xl mt-2">
                    KeneCare Virtual Hospital is an innovative platform in which we strive to provide accessible and
                    affordable digital health care solutions in Sierra Leone. We achieve this by changing the
                    patient-doctor experience in the medical sector. <br/> <br />

                    Kenecare Virtual Hospital is created and powered by IMO Tech Solutions Limited; an Information
                    Technology (IT) consulting firm with special focus on Managed IT Services, Cyber Security,
                    Cloud Solutions, and Database Administration.
                    </p>


                </div>

                <div
                    className="
                    flex items-center justify-center p-6 mt-8
                    lg:max-w-6xl xl:max-w-6xl
                    lg:w-2/4
                    "
                >
                    <img
                    src={medical_kit}
                    alt="Service"
                    className="object-contain object-center h-full w-full"
                    />
                </div>
            </div>
        </section>
    </>
  )
}

export default Banner

