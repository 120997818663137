import React from 'react'
import doctor_vector from '../img/doctor_vector.png'
import { Link } from 'react-router-dom'

function Banner() {

  return (
    <>
        <section
            className="
            text-gray-800 w-full flex bg-resources_banner_bg
            items-center justify-center bg-blue-500"
            id="home"
        >
            <div
            className="
            flex flex-col justify-center p-2
            sm:py-10 lg:py-24 lg:px-8 lg:flex-row
            lg:justify-between pb-4 pt-10
            xl:ml-5 w-10/12 max-w-7xl mt-10
            "
            >
                <div
                    className="
                    flex flex-col justify-center
                    lg:max-w-xl xl:max-w-2xl lg:text-left
                    "
                >
                    <h2 className='sm:text-6xl text-5xl text-white font-bold mt-2'>
                        SKIP THE TRAVEL! <br />
                        TAKE ONLINE DOCTOR CONSULTATION <br />
                    </h2>

                    <a target='_blank'
                    href={`https://app.kenecare.com/`}
                    className='bg-blue-700 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-48 text-center font-bold text-lg shadow mt-4' rel="noreferrer"
                    >
                        Consult Now
                    </a>


                </div>

                <div
                    className="
                    flex items-center justify-center p-6 mt-8
                    lg:max-w-6xl xl:max-w-6xl
                    lg:w-2/4
                    "
                >
                    <img
                    src={doctor_vector}
                    alt="Service"
                    className="object-contain object-center h-full w-full"
                    />
                </div>
            </div>
        </section>
    </>
  )
}

export default Banner

