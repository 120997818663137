import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "./Hfeed.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../header";
import Footer from "../footer";
import API from "../../Utils/Api";
import { html_entity_decode } from "../../Utils/Common";
import loader from "../../assests/loader.gif";
import { Helmet } from "react-helmet-async";

const Hfeed = () => {
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = `Health Feed | Kenecare`;
    document.getElementById("bodywrap").className = "hfeedmain";

    API.get("blogs")
      .then((response) => {
        if (response.data.status === 0) {
          setError("An error occurred"); // Assuming you want to show an error
        } else {
          setBlog(response.data.data);
        }
      })
      .catch((error) => {
        setError("Failed to fetch data. Please try again later."); // Handling fetch errors
      })
      .finally(() => {
        setIsLoading(false); // This ensures loading is stopped regardless of the result
      });
  }, []);

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <img className="w-40 h-40" src={loader} alt="Loading..." />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!blog || blog.length === 0) {
    return <p>No blog found.</p>;
  }

  return (
    <>
      <Helmet>
        <title>Health Articles | Kenecare</title>
        <meta
          name="description"
          content="Read top health articles, trending tips from doctors and health experts."
        />
        <link rel="canonical" href="/health-feed" />
      </Helmet>
      {/* SEO PURPOSE */}
      <Header />
      <section className="hfeed-wrap">
        <Container>
          <Row>
            <Col md={12} className="px-0 hfeed-top">
              <h5 className="text-3xl sm:mt-10">Top health articles</h5>
              <p className="text-xl">
                Trending tips from doctors and health experts
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="hfeed-cont py-2 ">
              <Row>
                {blog.length > 0 &&
                  blog.map((item, index) => {
                    return (
                      <Col md={4} className="hfeed-loop overflow-hidden">
                        <Link to={`/blog-detail?id=${item.blogId}`}>
                          <img
                            src={item.image}
                            className="img-fluid blog-image"
                            alt="hf1"
                          />
                        </Link>
                        <ul class="share py-2 uppercase">
                          <li>{item.blogCategory}</li>
                        </ul>

                        <div className="w-full mt-1 mb-2 overflow-x-auto flex flex-wrap gap-2">
                          {typeof item.tags === "string"
                            ? item.tags.split(",") &&
                              item.tags.split("#").map((tag, index) => (
                                <span
                                  key={index}
                                  className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5
                              rounded dark:bg-blue-200 dark:text-blue-800"
                                >
                                  {tag.trim()}
                                </span>
                              ))
                            : Array.isArray(item.tags)
                            ? item.tags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5
                              rounded dark:bg-blue-200 dark:text-blue-800"
                                >
                                  {tag.trim()}
                                </span>
                              ))
                            : null}
                        </div>

                        <h3>
                          <a
                            className="hover:text-orange-500 font-bold"
                            href={`/blog-detail?id=${item.blogId}`}
                          >
                            {html_entity_decode(item.blogTitle)}
                          </a>
                        </h3>
                        <p className="blog-des-box py-2 text-gray-500 test-justify text-xs">
                          <span className="font-semibold">Author: </span>{" "}
                          {item.author}{" "}
                          <span className="font-semibold">Created:</span>{" "}
                          {item.createdAt}{" "}
                          <span className="font-semibold">Featured:</span>{" "}
                          {item.featured}
                        </p>
                        <p>
                          <div
                            className="text-justify h-32 overflow-hidden"
                            dangerouslySetInnerHTML={{
                              __html: html_entity_decode(item.description),
                            }}
                          />
                        </p>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default Hfeed;
