import React, { useEffect, useState } from 'react';
import Api from '../../Utils/Api';
import profile_icon from '../../images/profile_icon.png'
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import left_arrow from '../../images/findDoctor/left_arrow.json'

const SearchForDoctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [filter, setFilter] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState(null); // State for selected doctor

  // Fetching doctors
  useEffect(() => {
    Api.get('/doctors')
      .then(response => {
        setDoctors(response.data.data);
      })
      .catch(error => {
        console.error("There was an error fetching the doctors data:", error);
      });
  }, []);

  // Fetching locations
  useEffect(() => {
    Api.get('/cities')
      .then(response => {
        setLocations(response.data.data);
      })
      .catch(error => {
        console.error("There was an error fetching the locations data:", error);
      });
  }, []);

  const handleSearchChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleDoctorSelect = (doctor) => {
    setSelectedDoctor(doctor); // Set the selected doctor to show in the sidebar
  };

  const filteredDoctors = doctors.filter(doctor => {
    // If a location is selected, find the corresponding city name
    const selectedCityName = locations.find(location => location.cityId.toString() === selectedLocation)?.cityName;
    const matchesLocation = selectedLocation ? doctor.location === selectedCityName : true;

    // Convert the doctor's name and specialization to lowercase for case-insensitive comparison
    const doctorFullName = `${doctor.firstName} ${doctor.middleName} ${doctor.lastName}`.toLowerCase();
    const doctorSpecialization = doctor.specialization.toLowerCase();

    // Check if the search term is included in the doctor's name or specialization
    const matchesSearchTerm = doctorFullName.includes(filter) || doctorSpecialization.includes(filter);

    // Only include doctors that match both the location and the search term filters
    return matchesLocation && matchesSearchTerm;
  });

  return (
    <div className="w-full">
        <div className='flex flex-col justify-center items-center h-[15rem] bg-blue-700 w-fill'>
            <h1 className="text-3xl font-bold text-white">Search for Doctors</h1>
            <p className="text-white text-lg">Find the right doctor for you</p>
            <div className='flex justify-center items-center w-full mt-3'>
                <Link to="/find-doctor" className="flex justify-center items-center">
                    <Lottie animationData={left_arrow} width={100} height={100} loop={true} className='w-16' />
                    <p className="text-white ml-4 text-lg">Go Back</p>
                </Link>
            </div>
        </div>

      <div className="flex flex-col md:flex-row gap-2 justify-center items-center mb-4 mt-10 px-2">
        <select
          className="p-2 border rounded w-full max-w-[25rem] px-2"
          onChange={handleLocationChange}
          value={selectedLocation}
        >
          <option value="">Select a location</option>
          {locations.map((location) => (
            <option key={location.cityId} value={location.cityId.toString()}>
              {location.cityName}
            </option>
          ))}
        </select>

        <input
          type="text"
          className="p-2 border rounded w-full max-w-[25rem]"
          placeholder="Search by specialization, name..."
          onChange={handleSearchChange}
        />
      </div>

    <div className="flex justify-center items-center mt-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:max-w-7xl w-full px-2">
        {filteredDoctors.map(doctor => (
            <div onClick={() => handleDoctorSelect(doctor)} key={doctor.doctorId} className="border p-4 rounded-lg hover:border-blue-500 cursor-pointer">
                <img src={doctor.profilePic || profile_icon} alt="Doctor profile" className="w-32 h-32 rounded-full mx-auto"/>
                <h2 className="text-sm font-bold mt-2">{`${doctor.title} ${doctor.firstName} ${doctor.middleName} ${doctor.lastName}`}</h2>
                <p className="text-gray-600">Gender: {doctor.gender}, Location: {doctor.location}</p>
                <p className="text-gray-800">SLL: {doctor.consultationFee}</p>
                <p className="text-gray-800">specialization: {doctor.specialization}</p>
            </div>
            ))}
        </div>
    </div>

    {selectedDoctor && (
        <div className="fixed top-0 right-0 md:w-[500px] h-full bg-white shadow-lg z-10 overflow-auto">
          <button onClick={() => setSelectedDoctor(null)} className="p-4">Close</button>
          <div className="p-4">
            <img src={selectedDoctor.profilePic || profile_icon} alt="Doctor profile" className="w-32 h-32 rounded-full mx-auto"/>
            <h2 className="text-lg font-bold my-2">{`${selectedDoctor.title} ${selectedDoctor.firstName} ${selectedDoctor.middleName} ${selectedDoctor.lastName}`}</h2>
            <p className="text-gray-600">Doctor ID: {selectedDoctor.doctorId}</p>
            <p className="text-gray-600">Gender: {selectedDoctor.gender}, Location: {selectedDoctor.location}</p>
            <p className="text-gray-800">Consultation fee SLL:{selectedDoctor.consultationFee}</p>
            <p className="text-gray-800">Specialization: {selectedDoctor.specialization}</p>
            <div className="mt-4">
              <h3 className="font-bold">Professional Summary:</h3>
              <p>{selectedDoctor.professionalSummary}</p>
              <h3 className="font-bold mt-4">Qualifications:</h3>
              <p>{selectedDoctor.qualifications}</p>
              <h3 className="font-bold mt-4">Years of Experience:</h3>
              <p>{selectedDoctor.yearOfExperience} years</p>
              <h3 className="font-bold mt-4">Contact:</h3>
              <p>Email: {selectedDoctor.email}</p>
              <p>Phone: {selectedDoctor.mobileNumber}</p>
              <div className='flex w-full justify-center items-center'>
                <a href={`https://app.kenecare.com/viewSpecialty/${selectedDoctor.specialtyId}`}
                target='_blank' className="text-blue-500 text-xl font-semibold mt-3" rel="noreferrer">Consult now</a>
              </div>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default SearchForDoctors;
