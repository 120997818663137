import React from 'react'
import { Link } from "react-router-dom";

import mobile_img from '../images/mobile.png'
import android_store from '../images/android_store.png'
import ios_store from '../images/apple_store.png'

const DownloadApp = () => {
  return (
    <section className=" w-full flex justify-center items-center py-4 bg-white border-t">
        <div className='flex flex-col sm:flex-row w-10/12 justify-center items-center px-2'>
          <div >
            <img src={mobile_img} className="w-96" alt="fdscreen" />
          </div>
          <div >
            <h2 className='text-gray-700 text-2xl font-semibold'>Skip the waiting room.</h2>
            <h2 className='text-blue-500 text-xl'> Kenecare <span className='text-gray-600'>Mobile App</span></h2>
            <p className='mt-3 text-gray-600 text-lg'> Available of Android and iOS </p>
            <p className='text-gray-600 text-lg'> Available of Android and iOS </p>
            <p className='text-gray-600 text-lg'> Verified doctors respond in 5 minutes</p>
            <p className='text-gray-600 text-lg'> 100% Private and confidential</p>
            {/* <h2 className='text-blue-500 text-3xl font-semibold mt-3'> COMING SOON!</h2> */}
            {/* <Button variant="primary" type="button" onClick={handleNavigateConsultNow}>Consult now</Button> */}
            <div className='flex  mt-4'>
              <Link
                to={'https://play.google.com/store/apps/details?id=com.imotechsolutions.kenecare'}
                target='_blank'
              >
                <img
                src={android_store}
                alt='android app'
                className='w-24 hover:mt-1'
                />
              </Link>

              <Link
                to={'https://apps.apple.com/gb/app/kenecare/id6479252909'}
                target='_blank'
              >
                <img
                src={ios_store}
                alt='android app'
                className='w-24 hover:mt-1'
                />
              </Link>

            </div>
          </div>
        </div>
      </section>
  )
}

export default DownloadApp
