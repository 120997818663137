import React from "react";
import fb from '../assests/social-media-icons/icons_Facebook.svg'
import ig from '../assests/social-media-icons/icons_instagram.svg'
import twitter from '../assests/social-media-icons/icons_twitter.svg'
import linkedIn from '../assests/social-media-icons/icons_linkedin.svg'
import { Link } from "react-router-dom";

function Footer() {
  return (

<footer class=" bg-blue-800">
   <div class=" flex flex-col justify-center items-center">
            <div class="lg:w-1/2 w-full sm:pt-10 pt-5 lg:px-0 sm:px-6 flex sm:flex-row flex-col justify-between items-center sm:text-2xl
               text-lg max-w-7xl sm:gap-0 gap-2"
            >
               <a className="text-white hover:underline" href="/">Kenecare Home</a>
               <a className="text-white hover:underline" href="/know-more">About Kenecare</a>
               <a className="text-white hover:underline" href="/find-doctor">Find Doctors</a>
               <a className="text-white hover:underline" href="/book-appointment">Book an Appointment</a>
               <a className="text-white hover:underline" href="/health-feed">Health Articles</a>
            </div>
            <div className="bg-white w-10/12 h-line my-3"></div>
            <p className="text-white text-center sm:w-1/2 max-w-7xl">
               KeneCare Virtual Hospital is an innovative platform in which we strive to provide accessible and affordable
               digital health care solutions in Sierra Leone. We achieve this by changing the patient-doctor experience in
               the medical sector.
            </p>
            <a href="terms" alt='term' className="text-white font-bold text-lg my-2 hover:text-xl">
               Terms and Conditions
            </a>

            <div className="flex flex-row py-3 gap-4">
               <Link
                  to={'https://www.facebook.com/profile.php?id=61557081320902'}
                  target="_blank"
               >
                  <img
                     alt="facbook"
                     src={fb}
                     className="w-11 h-11"
                  />
               </Link>

               <Link
                  to={'https://www.instagram.com/kenecare2024/'}
                  target="_blank"
               >
                  <img
                     alt="instagram"
                     src={ig}
                     className="w-11 h-11"
                  />
               </Link>

               <Link
                  to={'https://twitter.com/kenecare99047'}
                  target="_blank"
               >
                  <img
                     alt="twitter"
                     src={twitter}
                     className="w-11 h-11"
                  />
               </Link>

               <Link
                  to={'https://www.linkedin.com/in/kene-care-5124522b7/'}
                  target="_blank"
               >
                  <img
                     alt="linkedin"
                     src={linkedIn}
                     className="w-11 h-11"
                  />
               </Link>
            </div>
            <div className="bg-blue-900 w-full py-2">
               <p className="text-white text-center">&#169; 2023 IMO-Technologies</p>
            </div>
   </div>
</footer>

);
}

export default Footer;
