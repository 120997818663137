import React, { useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Vcon.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../header";
import Footer from "../footer";
import Banner from "./components/Banner";
import OurDoctors from "./components/OurDoctors";
import HowItWorks from "./components/HowItWorks";

import ConsultTopDoctors from "../../components/home/ConsultTopDoctors";

import AvailableSpecialty from "../../components/findDoctor/AvailableSpecialty";
import { Helmet } from "react-helmet-async";

const Vcon = () => {
  useEffect(() => {
    // document.title = `Video Consultant | Kenecare`;
    document.getElementById("bodywrap").className = "vconmain";
  }, []);

  const handleNavigateConsult = (event) => {
    event.preventDefault();
    window.location.href = "/new-consultation";
  };

  return (
    <>
      {/* SEO PURPOSE */}
      <Helmet>
        <title>Book Appointment | Kenecare</title>
        <meta
          name="description"
          content="With Kenecare you can skip the travel and book a private online consultation with verified doctors in all specialties"
        />
        <link rel="canonical" href="/book-appointment" />
      </Helmet>
      {/* SEO PURPOSE */}
      <Header />
      <Banner />

      <div className="flex justify-center items-center w-full bg-white sm:py-16">
        <AvailableSpecialty />
      </div>

      <div className="flex justify-center items-center w-full bg-gray-700 sm:py-16">
        <ConsultTopDoctors />
      </div>

      <OurDoctors />

      <HowItWorks />

      <section className="w-full py-5 bg-blue-500 text-white">
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <h3>Still delaying your health concerns ?</h3>
              <p>Connect with Africa's top doctors online</p>
            </Col>
            <Col md={6} xs={12}>
              <Button className="w-32 mt-3">
                <Link
                  to="https://app.kenecare.com/"
                  className="text-blue-500 hover:text-white font-bold"
                >
                  Sign up now
                </Link>
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="vcon10">
        <Container>
          <Row>
            <Col>
              <h2>Faqs</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12}>
              <h4>
                <i class="fas fa-caret-right"></i> What is online doctor
                consultation?
              </h4>
              <p>
                Online doctor consultation or online medical consultation is a
                method to connect patients and doctors virtually. It is a
                convenient and easy way to get online doctor advice using doctor
                apps or telemedicine apps or platforms, and the internet.
              </p>
              <h4>
                <i class="fas fa-caret-right"></i> Are Your Online Doctors
                Qualified?
              </h4>
              <p>
                The privacy of our patients is critical to us, and thus, we are
                compli....
              </p>
              <h4>
                <i class="fas fa-caret-right"></i> What happens if I don’t get a
                response from a doctor?
              </h4>
              <p>What happens if I don’t get a response from a doctor?</p>
            </Col>
            <Col md={4} xs={12}>
              <h4>
                <i class="fas fa-caret-right"></i> What is online doctor
                consultation?
              </h4>
              <p>
                Online doctor consultation or online medical consultation is a
                method to connect patients and doctors virtually. It is a
                convenient and easy way to get online doctor advice using doctor
                apps or telemedicine apps or platforms, and the internet.
              </p>
              <h4>
                <i class="fas fa-caret-right"></i> Are Your Online Doctors
                Qualified?
              </h4>
              <p>
                The privacy of our patients is critical to us, and thus, we are
                compli....
              </p>
              <h4>
                <i class="fas fa-caret-right"></i> What happens if I don’t get a
                response from a doctor?
              </h4>
              <p>What happens if I don’t get a response from a doctor?</p>
            </Col>
            <Col md={4} xs={12}></Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Vcon;
