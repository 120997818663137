/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import waiting_room from "../../images/knowMore/waiting_room.json";
import "bootstrap/dist/css/bootstrap.min.css";
import Banner from "../../components/knowMore/Banner";
import Header from "../header";
import Footer from "../footer";
import { Link } from "react-router-dom";
/* Mapbox Start */
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
/* Mapbox End */

import ContactUs from "../../components/knowMore/ContactUs";
import WhatKenecareOffers from "../../components/knowMore/WhatKenecareOffers";
import security_lottie from "../../images/findDoctor/security_lottie.json";
import { Helmet } from "react-helmet-async";

const KnowMore = () => {
  const navigate = useNavigate();

  const handleConsultNow = (event) => {
    navigate("/direct-new-consultation");
  };

  return (
    <>
      {/* SEO PURPOSE */}
      <Helmet>
        <title>About | Kenecare</title>
        <meta
          name="description"
          content="KeneCare Virtual Hospital is an innovative platform in which we strive to provide accessible and affordable digital health care solutions in Sierra Leone."
        />
        <meta
          name="description"
          content="Kenecare Virtual Hospital is created and powered by IMO Tech Solutions Limited."
        />
        <link rel="canonical" href="/know-more" />
      </Helmet>
      {/* SEO PURPOSE */}
      <Header />
      <Banner />
      <WhatKenecareOffers />

      <section className="w-full py-5 flex justify-center items-center bg-white px-4">
        <div className="w-full max-w-7xl">
          <Row>
            <Col md={6} xs={12} className=" flex flex-col justify-center">
              <h2 className="font-bold text-3xl text-gray-700">
                Skip the waiting room
              </h2>
              <dev className="mt-3 text-gray-600 text-lg">
                <p>Consult with top doctors.</p>
                <p>Doctors usually respond within the hour.</p>
                <p>Verified and experienced Doctors.</p>
              </dev>

              <Button onClick={handleConsultNow} className="w-32 mt-3">
                <Link
                  to="https://app.kenecare.com/"
                  className="text-blue-500 hover:text-white font-bold"
                >
                  Sign up now
                </Link>
              </Button>
            </Col>
            <Col md={6} xs={12} className="">
              <Lottie
                animationData={waiting_room}
                width={200}
                height={200}
                loop={true}
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className="flex justify-center items-center bg-gray-100 sm:py-0 py-5 ">
        <div className="sm:w-10/12 w-full flex justify-center items-center max-w-7xl">
          <Col md={4} className="text-sm sm:text-lg">
            <h2 className="text-sm sm:text-3xl font-bold mb-3">
              Multi-level security checks <br /> <span>top priority.</span>
            </h2>
            <p>
              <i class="fas fa-check-circle"></i> Multi-level security checks
            </p>
            <p>
              <i class="fas fa-check-circle"></i> Multiple data backups
            </p>
            <p>
              <i class="fas fa-check-circle"></i> Stringent data privacy
              policies
            </p>
          </Col>
          <Col md={4}>
            <Lottie
              animationData={security_lottie}
              width={200}
              height={200}
              loop={true}
            />
          </Col>
        </div>
      </section>

      <section className="fd4-wrap bg-gray-100 py-5 flex justify-center items-center ">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-10 max-w-7xl w-full">
          <div className="flex flex-col items-center justify-center">
            <img
              src="assets/images/fdicn1.png"
              className="sm:h-20 h-16"
              alt="fdicn1"
            />
            <h3 className="text-lg">256-bit encryption</h3>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              src="assets/images/fdicn2.png"
              className="sm:h-20 h-16"
              alt="fdicn1"
            />
            <h3 className="text-lg">ISO 27001 certified</h3>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              src="assets/images/fdicn3.png"
              className="sm:h-20 h-16"
              alt="fdicn1"
            />
            <h3 className="text-lg">MDCSL Certified</h3>
          </div>
          {/* <div className='flex flex-col items-center justify-center'>
              <img src="assets/images/fdicn4.png" className="sm:h-20 h-16" alt="fdicn1" />
              <h4 className='text-lg'>DSCI member</h4>
            </div> */}
        </div>
      </section>
      <ContactUs />
      <Footer />
    </>
  );
};
export default KnowMore;
