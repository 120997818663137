import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../header";
import Footer from "../footer";
import { useNavigate, Link } from "react-router-dom";
import API from "../../Utils/Api";
import Banner from "../../components/findDoctor/Banner";
import AvailableSpecialty from "../../components/findDoctor/AvailableSpecialty";
import OurDoctors from "../../components/findDoctor/OutTopDoctors";
import Consultation from "../../components/knowMore/Consultation";
import DownloadApp from "../../components/DownloadApp";
import {Helmet} from "react-helmet-async";

const FindDoctor = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    API.get("blogs")
      .then((response) => {
        if (response.data.status === 0) {
        } else {
          setArticles(response.data.data);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      {/* SEO PURPOSE */}
      <Helmet>
        <title>Find Doctors | Kenecare</title>
        <meta
          name="description"
          content="Find verified doctors on Kenecare and book an appointment online. Kenecare is your home for health!"
        />
        <link rel="canonical" href="/find-doctor" />
      </Helmet>
      {/* SEO PURPOSE */}
      <Header />

      <Banner />

      <div className="w-full flex justify-center border-b  bg-gray-100 ">
        <OurDoctors />
      </div>
      <div className="w-full flex justify-center mt-7 border-b pb-10 ">
        <AvailableSpecialty />
      </div>

      {/* <Consult /> */}
      <Consultation />

      <DownloadApp />

      <Footer />
    </>
  );
};
export default FindDoctor;
