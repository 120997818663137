import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import API from '../../Utils/Api';
import { Button, Form } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import loader from '../../assests/loader.gif';

const Bdet = () => {
  const location = useLocation();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Ensure isLoading is true initially

  useEffect(() => {
    document.title = `Blog Detail | Kenecare`;
    document.getElementById('bodywrap').className = 'bdetmain';

    const { id } = queryString.parse(location.search);

    if (!id) {
      setError('No blog ID provided.');
      setIsLoading(false);
      return;
    }

    API.get(`blogs/?id=${encodeURIComponent(id)}`)
      .then(response => {
        if (response.data.status === 0) {
          setError(response.data.message);
        } else {
          // Assuming response.data.data is an array of blog posts
          const selectedBlog = response.data.data.find(blog => blog.blogId.toString() === id);
          if (selectedBlog) {
            setBlog(selectedBlog);
          } else {
            setError('Blog post not found.');
          }
        }
      })
      .catch(error => {
        setError("Failed to fetch blog details. Please try again later.");
        console.error('Fetch error:', error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false both on success and error
      });
  }, [location.search]); // Dependency on location.search to rerun effect when query params change

  if (isLoading) {

    return(
        <div className='w-screen h-screen flex justify-center items-center'>
          <img className='w-40 h-40' src={loader} alt="Loading..." />;
        </div>
    )
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!blog) {
    return <p>No blog found.</p>;
  }

  function renderTextWithLineBreaks(text) {
    // Split text by new lines and filter out empty lines if necessary
    const lines = text.split('\n').filter(line => line.length > 0);
    // Map each line to a <p> tag for better spacing, or use <br> if preferred
    return lines.map((line, index) => (
      <p key={index} className="mb-4 text-justify">
        {line}
      </p>
    ));
  }


  return (
    <>
      <Header />

        <div className='w-full flex'>

         <section className="bg-white flex lg:flex-row flex-col justify-center items-start lg:px-2 px-4 w-full">

            <div className='flex flex-col lg:w-view-blog-width w-full mt-10 rounded mb-5 bg-gray-100'>
              <div className="rounded-t-lg">
                <img className='rounded-t-lg' src={blog.image} alt="blog_image"   />
              </div>
              <div className="px-4 py-4">
                <div className="bdet-top-ch">
                  <div className="">
                    <h2 className='text-orange-500 text-2xl font-semibold'>{blog.blogTitle}</h2>
                  </div>

                  <div className="text-blue-500">
                    <p className="font-semibold text-lg uppercase my-4">{blog.blogCategory}</p>
                  </div>
                </div>

                <div className='flex flex-row gap-5 mb-3'>
                  <div className="flex flex-row gap-1">
                  <svg class="w-5 h-5 text-blue-800 hover:text-blue-600 cursor-pointer" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M15.03 9.684h3.965c.322 0 .64.08.925.232.286.153.532.374.717.645a2.109 2.109 0 0 1 .242 1.883l-2.36 7.201c-.288.814-.48 1.355-1.884 1.355-2.072 0-4.276-.677-6.157-1.256-.472-.145-.924-.284-1.348-.404h-.115V9.478a25.485 25.485 0 0 0 4.238-5.514 1.8 1.8 0 0 1 .901-.83 1.74 1.74 0 0 1 1.21-.048c.396.13.736.397.96.757.225.36.32.788.269 1.211l-1.562 4.63ZM4.177 10H7v8a2 2 0 1 1-4 0v-6.823C3 10.527 3.527 10 4.176 10Z" clip-rule="evenodd"/>
                  </svg>
                  <p className='text-gray-500 text-sm'>200</p>
                  </div>


                  <div className="flex flex-row gap-1">
                    <svg class="w-5 h-5 text-blue-800 hover:text-blue-600 cursor-pointer" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" d="M8.97 14.316H5.004c-.322 0-.64-.08-.925-.232a2.022 2.022 0 0 1-.717-.645 2.108 2.108 0 0 1-.242-1.883l2.36-7.201C5.769 3.54 5.96 3 7.365 3c2.072 0 4.276.678 6.156 1.256.473.145.925.284 1.35.404h.114v9.862a25.485 25.485 0 0 0-4.238 5.514c-.197.376-.516.67-.901.83a1.74 1.74 0 0 1-1.21.048 1.79 1.79 0 0 1-.96-.757 1.867 1.867 0 0 1-.269-1.211l1.562-4.63ZM19.822 14H17V6a2 2 0 1 1 4 0v6.823c0 .65-.527 1.177-1.177 1.177Z" clip-rule="evenodd"/>
                    </svg>

                  <p className='text-gray-500 text-sm'>0</p>
                  </div>


                  <svg class="w-5 h-5 text-blue-800 hover:text-blue-600 cursor-pointer" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z"/>
                  </svg>
                </div>

                <p className="blog-des-box py-2 text-gray-500 test-justify text-xs">
                  Author: {blog.author} Created: {blog.createdAt} Featured: {blog.featured}
                </p>

                <div className="text-gray-600 text-justify" />
                {renderTextWithLineBreaks(blog.description)}
              </div>
            </div>

            <div className="flex flex-col w-80 ml-10 px-2 py-4 bg-white">
              <h4 className='px-2 py-2 bg-blue-500 text-white'>Related Tags</h4>
              <div className='w-72 mt-4 overflow-x-auto flex flex-wrap gap-2'>
                {typeof blog.tags === 'string' ?
                  blog.tags.split(',').map((tag, index) => (
                    <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5
                      rounded dark:bg-blue-200 dark:text-blue-800"
                    >
                      {tag.trim()}
                    </span>
                  ))
                  : Array.isArray(blog.tags) ?
                  blog.tags.map((tag, index) => (
                    <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5
                      rounded dark:bg-blue-200 dark:text-blue-800"
                    >
                      {tag.trim()}
                    </span>
                  ))
                  : null
                }
              </div>
              <div className="hh-side mx-0">
                <div>
                  <img src="assets/images/mail.png" alt='mail-box' className="w-auto" />
                  <h6>Get health tips in your inbox</h6>
                  <div md={12} className="px-0">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="Your Email Address" />
                    </Form.Group>
                    <Button variant="primary" type="button" className="bg-blue-400 w-full">Submit</Button>
                  </div>
                </div>
              </div>
            </div>

          </section>

      </div>

      <Footer />
    </>
  )
}
export default Bdet;
