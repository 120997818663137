import React, { useState, useEffect } from 'react';
import Api from '../../../Utils/Api';
import { Container } from 'react-bootstrap';
import '../Vcon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import dr_v1 from '../img/dr_v1.png'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const OurDoctors = () => {


  const [doctorsData, setTopDoctors] = useState([]);

  useEffect(() => {
    const fetchTopDoctors = async () => {
      try {
        const response = await Api.get('/doctors');
        if (response.data.status === 0) {
          // Handle error if needed
        } else {
          // Slice the data to get only top 5 doctors
          setTopDoctors(response.data.data.slice(0, 5));
        }
      } catch (error) {
        // Handle error if the API request fails
        console.error('Error fetching top doctors:', error);
      }
    };

    fetchTopDoctors();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <section className="w-full py-5 bg-white px-2">
      <Container className='max-w-7xl '>
        <div className='my-4'>
            <div>
                <h2 className='text-gray-700 text-2xl font-semibold'>Our Doctors</h2>
                <p className='text-gray-700 text-xl'>Consult a doctor online for any health issue</p>
            </div>
        </div>

          <Slider {...settings}>
            {doctorsData.map((doctor) => (
              <div  className='flex justify-center items-center w-full'>
                <div className="flex flex-row w-[20rem] rounded shadow  bg-white">
                  <div>
                    <img className='w-[4rem] h-[4rem]' src={doctor.profilePic || dr_v1 } alt={doctor.name} />
                    <h6 className='ml-2 bg-yellow-400 rounded text-center my-2 text-white text-sm font-bold w-10'>5</h6>
                  </div>
                  <div className='mt-2 ml-2'>
                    <h3 className='text-blue-500'>{doctor.title} {doctor.firstName} {doctor.middleName} {doctor.lastName}</h3>
                    <p>{doctor.specialization}</p>
                    <p>{doctor.experience}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
      </Container>
    </section>
  );
};

export default OurDoctors;
