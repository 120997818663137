import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";


import doctor_image_home from '../../images/home/doctor_image_home.png'

function Banner() {

  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

    const navigate = useNavigate();
    const [city, setCity] = useState([]);
    const [section1, setSection1] = useState([]);
    const [inputs, setInputs] = useState({ location: '', doctor_name: ''});
    const [suggestions, setSuggestions] = useState([]);


    useEffect(() => {
      document.title = `Home | Kenecare`;
      document.getElementById("bodywrap").className = "homemain";

      apiInstance.get("cities")
            .then((response) => {
                if (response.data.status === 0) {
                    // Handle error if needed
                } else {
                    // Set the city state with the data from the API
                    setCity(response.data.data);
                }
            })
            .catch((error) => {
                // Handle error if the API request fails
            });

    }, []);

    const anchor = () => {
      navigate("/know-more");
    };




    const fetchDoctors = (locationId, doctorName) => {
      const apiUrl = `doctors?locationId=${locationId}&q=${doctorName}`;
      //axios
      apiInstance.get(apiUrl)
        .then((response) => {
          if (response.data.status === "success") {
            setSection1(response.data.data);
          } else {
            console.error("API request failed");
          }
        })
        .catch((error) => {
          console.error("API request error:", error);
        });
    };

    const handleSearchDoctor = (event) => {
      event.preventDefault();
      const { location, doctor_name } = inputs;
      fetchDoctors(location, doctor_name);
      navigate("/search-doctor?loc=home", { state: inputs });
    };

    const handleInputChange = (event) => {
      event.persist();
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));

      // Fetch doctor name suggestions as the user types
      const { location, doctor_name } = inputs;
      const apiUrl = `${apiInstance}doctors?locationId=${location}&q=${doctor_name}`;
      console.log("API URL for suggestions:", apiUrl);
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.data.status === "success") {
            setSuggestions(response.data.data);
          } else {
            setSuggestions([]);
          }
        })
        .catch((error) => {
          console.error("API request error:", error);
          setSuggestions([]);
        });
    };

    // Add function to handle suggestion click
    const handleSuggestionClick = (doctor) => {
      setInputs((inputs) => ({ ...inputs, doctor_name: doctor.firstName + ' ' + doctor.lastName }));
      setSuggestions([]);
    };


  return (
    <>
        <section
            className="
            text-gray-800 w-full flex bg-resources_banner_bg
            items-center justify-center bg-blue-500"
            id="home"
        >
            <div
            className="
            flex flex-col-reverse justify-center p-2
            sm:py-10 lg:py-24 lg:px-8 lg:flex-row-reverse
            lg:justify-between pb-4 pt-10
            xl:ml-5 w-4/5 max-w-7xl mt-10
            "
            >
                <div
                    className="
                    flex flex-col justify-center
                    lg:max-w-xl xl:max-w-2xl lg:text-left
                    "
                >
                    <h1 className='sm:text-6xl text-4xl text-white font-bold mt-2'>
                        You are in safe hands<br />
                        <span className='sm:text-3xl text-sm mt-3'>
                          Connect to health care experts with just a tap!
                        </span><br />
                    </h1>

                    <div className="">
                        <div className="home-right m-0 p-0">
                        <div className="text-center">
                            <button type="button"
                            className="px-10 py-2 my-5 rounded bg-blue-700 hover:bg-blue-600 text-white"
                            onClick={anchor} >
                            Know More
                            </button>
                        </div>

                        </div>
                    </div>
                </div>

                <div
                    className="
                    flex items-center justify-center p-6 mt-8
                    lg:max-w-6xl xl:max-w-6xl
                    lg:w-2/4
                    "
                >
                    <img src={doctor_image_home} alt="doctor_image_home" />
                </div>
            </div>
        </section>
    </>
  )
}

export default Banner

