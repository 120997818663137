/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import doctor1 from '../../images/knowMore/doctor1.png';
import doctor2 from '../../images/knowMore/doctor2.png'


const consultants = [
  {
    button: "Consult",
    id: 1,
    name: " CONSULTATION",
    specialization: "Consultation made easy",
    description: `Consultations can easily be done through video calls which are simply accessed through laptops or mobile phones.
      With live video-consultancy, prescriptions, medical records and laboratory options all incorporated in one platform,
      we are providing an all in-one solution to receiving the best care.
      `,
    image: doctor1,
    btn_name: "Consult",
    consult_BTN: "https://app.kenecare.com/",
  },
  {
    button: "Why It Works",
    id: 2,
    name: "A Simple Digital Solution",
    specialization: "Connect with Doctors online",
    description: `Kenecare Virtual Hospital connects doctors to patients from the comforts of their homes/offices or any other
      location. By increasing efficiency of care delivery, reducing expenses of transporting patients to hospital,
      providing the best medical consultation to most distant patients. We can save time; in return we may save more lives.`,
    image: doctor2,
    btn_name: "Sign Up Now",
    consult_BTN: "https://app.kenecare.com/",
  },
  // Add more consultants here
];

const Consultation = () => {
    const [activeConsultant, setActiveConsultant] = useState(1);

  const toggleConsultant = (id) => {
    if (activeConsultant === id) {
      setActiveConsultant(null); // Toggle off if already active
    } else {
      setActiveConsultant(id); // Activate the selected consultant
    }
  };
  return (
    <section className="bg-white py-10 flex justify-center" id="ourExperts">
      <div className=" w-10/12 sm:m-8 max-w-7xl">
        <h2 className="sm:text-3xl text-2xl text-center font-bold mb-5">
          Our Dedicated Experts
        </h2>

        <div className="flex-col md:flex-row mb-10">
          {consultants.map((consultant) => (
            <Link
              key={consultant.id}
              to="#"
              className={`inline-block ${
                activeConsultant === consultant.id
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-300 text-gray-700 hover:bg-blue-500'
              } md:w-auto w-full px-10 py-3 h-fit sm:ml-4 hover:text-white rounded transition
              duration-300 ease-in-out font-md text-sm justify-center items-center text-center`}
              onClick={() => toggleConsultant(consultant.id)}
            >
              {consultant.button}
            </Link>
          ))}
        </div>

        <div
          className="flex flex-wrap flex-col-reverse sm:flex-row-reverse w-full"
        >
          {consultants.map((consultant) => (
            <div
              key={consultant.id}
              className={`w-full sm:w-1/2 sm:p-6 sm:px-5 flex flex-col justify-center ${
                activeConsultant === consultant.id ? '' : 'hidden'
              }`}
            >
              <h2 className="text-3xl text-gray-700 font-bold mt-2">
                {consultant.name}
              </h2>
              <h3 className="text-2xl text-orange-500 mt-4">
                {consultant.specialization}
              </h3>
              <p className="text-gray-600 xl:text-2xl text-sm mb-8 text-justify max-w-2xl mt-5">
                {consultant.description}
              </p>
              <Link
                to={consultant.consult_BTN}
                className='
                  bg-blue-500 text-white text-center rounded py-2
                  hover:bg-blue-400 shadow
                  '
              >
                  {consultant.btn_name}
              </Link>
            </div>
          ))}
          <div className="w-full sm:w-1/2 sm:order-first flex flex-col justify-center items-center">
            {consultants.map((consultant) => (
              <div
                key={consultant.id}
                className={`xl:w-full xl:h-full w-full flex justify-center ${
                  activeConsultant === consultant.id ? '' : 'hidden'
                }`}
              >
                <img
                  className="w-1/2"
                  src={consultant.image}
                  alt="image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Consultation

