import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Hfeed from "./Pages/HealthFeed/Hfeed.jsx";

import FindDoctor from "./Pages/FindDoctor/Finddoctor.jsx";
import BookAppointment from "./Pages/BookAppointment/BookAppointment.jsx";
import Bdet from "./Pages/blog/Bdet.jsx";
import KnowMore from "./Pages/KnowMore/KnowMore";
import DirectionMap from "./components/knowMore/DirectionMap";
import AllServicesPage from "./components/knowMore/AllServicesPage";
import ViewSpecialty from "./components/findDoctor/ViewSpecialty.jsx";
import SearchForDoctors from "./components/findDoctor/SearchForDoctors.jsx";
import Terms from "./components/Terms.js";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/search-for-doctors/"
            element={<SearchForDoctors />}
          />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home/" element={<Home />} />

          <Route
            path="/viewSpecialty/:specialtyId"
            element={<ViewSpecialty />}
          />

          <Route exact path="/terms" element={<Terms />} />

          <Route exact path="/location/" element={<DirectionMap />} />
          <Route exact path="/all-services" element={<AllServicesPage />} />

          <Route exact path="/book-appointment/" element={<BookAppointment/>} />
          <Route exact path="/find-doctor/" element={<FindDoctor />} />

          <Route exact path="/two-factor-authantication/" />
          <Route exact path="/health-feed/" element={<Hfeed />} />
          <Route exact path="/blog-detail" element={<Bdet />} />

          <Route exact path="/know-more" element={<KnowMore />} />
        </Routes>
      </Router>
    </>
  );
};
export default App;
