/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import digital_health from "../../images/knowMore/digital_health.png";
import health_education from "../../images/knowMore/health_education.png";
import telemedicine from "../../images/knowMore/telemedicine_consultations.png";
import prescription_services from "../../images/knowMore/prescription_services.png";
import { useNavigate } from "react-router-dom";

const services = [
  {
    title: "Digital Health Records",
    description:
      "Patients have easy access to their digital health records, allowing them to review medical history, test results, and treatment plans online.  ",
    img_file: digital_health,
  },
  {
    title: "Health Education and Information",
    description:
      "Kenecare provides patients with a wealth of educational resources, articles, and videos to empower them with knowledge about their conditions and healthcare options.",
    img_file: health_education,
  },
  {
    title: "Telemedicine Consultations",
    description: `Kenecare offers remote consultations with experienced healthcare professionals, including doctors, nurses, and specialists. `,
    img_file: telemedicine,
  },

  {
    title: "Prescription Services",
    description: `Kenecare's virtual hospital provides electronic prescription services. Doctors can electronically send prescriptions to the patient's chosen pharmacy, making it convenient to obtain necessary medications. `,
    img_file: prescription_services,
  },
];

const WhatKenecareOffers = () => {
  const navigate = useNavigate();

  // Define a function to handle the button click event
  const handleViewAllClick = () => {
    // Define the data you want to pass
    const servicesData = services;

    // Navigate to the "/all-services" route with the data as state
    navigate("/all-services", { state: { servicesData } });
  };

  return (
    <section className="bg-gray-100 pb-24 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title and section separator */}
        <div className="text-center py-16 flex flex-col items-center justify-center">
          <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            What Kenecare Offers
          </p>
          <div
            className="w-16 h-1 bg-blue-500 mt-2"
            // data-aos="fade-right"
          ></div>
        </div>

        {/* Services Grid */}
        <div className="max-w-3xl mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
          {services.slice(0, 6).map((service) => (
            // Individual service card
            <div
              key={service.title}
              className="bg-white overflow-hidden shadow rounded-lg"
              // data-aos="fade-up"
            >
              {/* Service Image */}
              <div className="relative flex w-full justify-center pt-3 h-48">
                <img
                  src={service.img_file}
                  alt="Image Description"
                  height={200}
                  width={200}
                />
              </div>

              {/* Service Title and Description */}
              <div className="px-2 py-2 sm:p-2">
                <div className="flex items-center justify-center">
                  <div className="">
                    <div className="text-lg font-bold leading-6 text-gray-900">
                      {service.title}
                    </div>
                    <div className="mt-2 text-base text-gray-500">
                      {service.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* "View All" button */}
        {/* <div className="w-full mt-5">
          <button
            onClick={handleViewAllClick}
            className="font-bold text-white text-xl bg-blue-500 py-2 px-10 text-center rounded mt-5"
          >
            View All
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default WhatKenecareOffers;
