import React from 'react';
import { Link } from 'react-router-dom';
const DoctorPopup = ({ doctor, onClose }) => {
    const dashboard = 'https://app.kenecare.com/';
    return (
      <>
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white flex flex-col items-center justify-center p-3 w-popup-width sm-w-full rounded-md shadow-md">
        <div className='w-full'>
            <button className="text-red-500 mb-3" onClick={onClose}>Close</button>
        </div>
        <img src={doctor.profilePic} alt={doctor.firstName} className="w-48 rounded-full mb-2" />
        <p className="text-center font-Poppins">{`${doctor.title} ${doctor.firstName} ${doctor.middleName} ${doctor.lastName}`}</p>
        <p className='text-xs'><span className='text-gray-900 font-semibold'>Doctor Id:</span>  {doctor.doctorId}</p>
        <div className='w-full font-Poppins text-sm text-gray-700'>
            <div className='w-48 h-0.5 bg-orange-500 my-3'></div>
            <p><span className='text-gray-900 font-semibold'>Gender:</span>  {doctor.gender}</p>
            <p><span className='text-gray-900 font-semibold'>specialization:</span>  {doctor.specialization}</p>
            <p><span className='text-gray-900 font-semibold'>qualifications:</span>  {doctor.qualifications}</p>
            <p><span className='text-gray-900 font-semibold'>Years of experience:</span>  {doctor.yearOfExperience}</p>
            <p><span className='text-gray-900 font-semibold'>email:</span>  {doctor.email}</p>
            <p><span className='text-gray-900 font-semibold'>phone:</span>  {doctor.mobileNumber}</p>
            <p><span className='text-gray-900 font-semibold'>Location:</span>  {doctor.location}</p>
            <p><span className='text-gray-900 font-semibold'>ConsultationFee:</span>  {doctor.consultationFee}</p>
            <div className='w-full mt-3 mb-5'>
                <span className='text-gray-900 font-semibold'>ProfessionalSummary:</span>
                <p className='text-justify'>{doctor.professionalSummary}</p>
            </div>
        </div>
        <Link to={dashboard} target="_blank" rel="dashboard"
            className="bg-blue-500 hover:bg-blue-400
            py-2 px-3 rounded text-sm text-white">
            Login to your dashboard to book appointment
        </Link>
      </div>
    </div>
      </>
  );
};

export default DoctorPopup;
