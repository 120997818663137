import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../Vcon.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const HowItWorks = () => {

  return (
    <>
        <section className="vcon5 bg-white sm:py-16">
        <Container>
        <Row className='mb-8'>
            <Col>
            <h2>How it works</h2>
            </Col>
        </Row>
        <Row>
            <Col md={4} xs={12}>
            <h4>1</h4>
            <p>Select A Specialty Or Symptom</p>
            </Col>
            <Col md={4} xs={12}>
            <h4>2</h4>
            <p>Audio/ video call with a verified doctor</p>
            </Col>
            <Col md={4} xs={12}>
            <h4>3</h4>
            <p>Get a digital prescription & a free follow-up</p>
            </Col>
        </Row>
        </Container>
    </section>

  </>
  )
}

export default HowItWorks
