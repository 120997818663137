import React, { useState, useEffect } from 'react';
import Api from '../../Utils/Api';
import profile_icon from '../../images/profile_icon.png';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const OutTopDoctors = () => {
  
  const [topDoctors, setTopDoctors] = useState([]);

  useEffect(() => {
    const fetchTopDoctors = async () => {
      try {
        const response = await Api.get('/doctors');
        if (response.data.status === 0) {
          // Handle error if needed
        } else {
          // Slice the data to get only top 5 doctors
          setTopDoctors(response.data.data.slice(0, 5));
        }
      } catch (error) {
        // Handle error if the API request fails
        console.error('Error fetching top doctors:', error);
      }
    };

    fetchTopDoctors();
  }, []);

  const owlCarouselOptions = {
    responsiveClass:true,
    margin: 10,
    responsive:{
        0:{
            items:1,
            nav:true,
        },
        600:{
            items:2,
            nav:true
        },
        1000:{
            items:3,
            nav:true
        }
    }
  }

  return (
    <div className="flex flex-col w-full max-w-7xl justify-center items-center py-8">
      <h1 className="text-2xl font-bold mb-2">Top Doctors</h1>
      <p className='mb-4 text-lg text-gray-700'> Our top five doctors for the week</p>
      <OwlCarousel
          className='owl-theme flex justify-center items-center w-full py-4 z-0'
          autoplaySpeed="2000"
          autoplay="true"
          loop="true"
          center="true"
          navigation='true'
          {...owlCarouselOptions}>
        {topDoctors.slice(0,5).map((doctor) => (
          <div key={doctor.id} className="flex flex-row bg-white h-40 w-96  rounded-md shadow">
            <div className="w-1/2 bg-blue-600 rounded-l-md">
            <img
              src={doctor.profilePic || profile_icon}
              alt={doctor.name}
              className="flex object-cover object-center rounded-l-md "
            />
            </div>

            <div className='flex flex-col justify-center ml-2'>
                <h2 className="font-semibold text-xs">{doctor.title} {doctor.firstName} {doctor.middleName} {doctor.lastName}</h2>
                <p className="text-gray-600 mb-2 text-xs">Specialization: {doctor.specialization}</p>
                <p className="text-gray-600 mb-2 text-xs">Consultation fee: {doctor.consultationFee}</p>
                <p className="text-gray-600 mb-2 text-xs">ID: {doctor.doctorId}</p>
                <p className="text-gray-600 text-xs">Ratings: <span className='px-3 rounded bg-yellow-400'> 4 </span></p>
            </div>

          </div>
        ))}
        </OwlCarousel>

    </div>
  );
};

export default OutTopDoctors;
