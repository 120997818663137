import axios from 'axios';
import { getAuthToken, currentDateTime, encrypt } from './Common';
const AUTH_TOKEN = getAuthToken();
const CURR_TIME = encrypt(currentDateTime());

export default axios.create({
    baseURL: 'https://api.kenecare.com/api/v1/',
    timeout: 500000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "access-control-allow-origin": "*",
        'x-access-token': AUTH_TOKEN,
        'x-unq-key': CURR_TIME,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    }
});
