/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../header";
import Footer from "../footer";

import API from "../../Utils/Api";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from "jquery";
import Banner from "../../components/home/Banner";
import DownloadApp from "../../components/DownloadApp";
import ConsultTopDoctors from "../../components/home/ConsultTopDoctors";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import profile_icon from "../../images/profile_icon.png";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const navigate = useNavigate();
  const [testimonial, setTestimonial] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [departmentTypes, setDepartmentTypes] = useState([]);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    document.title = `Home | Kenecare`;
    document.getElementById("bodywrap").className = "homemain";

    API.get("testimonials")
      .then((response) => {
        if (response.data.status === 0) {
        } else {
          console.log(response.data.data);
          setTestimonial(response.data.data);
        }
      })
      .catch((error) => {});

    API.get("specialization")
      .then((response) => {
        if (response.data.status === 0) {
        } else {
          setSpecialization(response.data.data);
        }
      })
      .catch((error) => {});

    API.post("department_types")
      .then((response) => {
        if (response.data.status === 0) {
        } else {
          setDepartmentTypes(response.data.data);
        }
      })
      .catch((error) => {});

    API.get("blogs")
      .then((response) => {
        if (response.data.status === 0) {
        } else {
          setArticles(response.data.data);
        }
      })
      .catch((error) => {});
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
      justifyContent: "center",
    },
  };

  return (
    <>
    {/* SEO PURPOSE */}
      <Helmet>
        <title>Home | Kenecare</title>
        <meta
          name="description"
          content="With Kenecare you are in safe hands. Connect with health care experts with just a tap!"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      {/* SEO PURPOSE */}
      <Header />

      <Banner />

      <DownloadApp />

      <div className="bg-blue-800 flex justify-center items-center flex-col w-full">
        <ConsultTopDoctors />
      </div>

      <section className="flex flex-col justify-center items-center bg-gray-100 py-5">
        <div className="flex flex-col w-full max-w-7xl px-2">

            <h1 className='text-gray-600 text-3xl'>Read top articles from health experts</h1>
          <h2 className="text-gray-500 text-xl">

            Health articles and notices that keep you informed.
          </h2>

          <Row>
            {articles.length > 0 &&
              articles.slice(0, 3).map((item, index) => {
                return (
                  <Col md={4} xs={12}>
                    <div className="rounded-lg shadow sm:h-article-card pb-2 overflow-hidden bg-white sm:mt-0 mt-4">
                      <img src={item.image} className="w-full h-52" alt="hm1" />
                      <Link
                        to={`/blog-detail?id=${item.blogId}`}
                        target="_blank"
                      >
                        <h3 className="p-2 text-lg text-gray-600 font-semibold hover:text-orange-500">
                          {item.blogTitle}
                        </h3>
                      </Link>
                      <div className="w-full px-2">
                        <small className="text-blue-500 text-sm text-center">
                          {item.blogCategory}
                        </small>
                      </div>
                      <p className="blog-des-box p-2 text-gray-500 test-justify">
                        {item.description.slice(0, 150)} .....
                      </p>

                      <p className="blog-des-box p-2 text-gray-500 test-justify text-xs">
                        <span className="font-semibold">Author: </span>{" "}
                        {item.author}{" "}
                        <span className="font-semibold">Created:</span>{" "}
                        {item.createdAt}{" "}
                        <span className="font-semibold">Featured:</span>{" "}
                        {item.featured}
                      </p>
                    </div>
                  </Col>
                );
              })}
            <div className="text-center flex justify-center items-center w-full mt-4">
              <Link
                className="hover:bg-blue-400 bg-blue-500 text-white px-6 py-2 rounded"
                to={`/health-feed`}
              >
                More Articles
              </Link>
            </div>
          </Row>
        </div>
      </section>

      <section className=" flex justify-center items-center bg-white w-full pt-6 border-b">
        <Container>
          <Row>
            <Col md={12} xs={12} className="text-center hms-top my-4">
              <h1 className="text-3xl">Testimonial</h1>
              <p className="text-xl">
                Trusted By <span className="text-orange-400">10K customer</span>
              </p>
            </Col>
          </Row>

          <div className="flex justify-center items-center w-full">
            <div className="w-full max-w-7xl">
              <Carousel
                responsive={responsive}
                infinite
                draggable
                swipeable
                centerMode={false}
                autoPlay={true}
                keyBoardControl={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
              >
                {testimonial.length > 0 &&
                  testimonial.map((item, index) => {
                    return (
                      <div className="w-full flex flex-row justify-center items-center px-2">
                        <div
                          className="bg-white py-4 flex mb-5 mt-2 border px-2
                            flex-col justify-center items-center h-64 rounded-md overflow-hidden
                          "
                        >
                          <p className="text-gray-500 text-sm">
                            {item.content}
                          </p>
                          <div className="w-16  flex  justify-center items-center">
                            <img
                              src={ item.patientPic || profile_icon }
                              className="rounded-full w-16 h-16"
                              alt="quote"
                            />
                          </div>
                          <p className="text-orange-500">{item.patientName}</p>
                        </div>
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          </div>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Home;
