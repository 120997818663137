/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'


const AllServicesPage = (props) => {
  // Use useLocation to access location state
  const location = useLocation();
  const { servicesData } = location.state || {};

  // Log servicesData to the console
  console.log("servicesData: shows here", servicesData);

  return (
    <div className="bg-gray-100 pb-16">

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-0 mb-10">
        {/* Title and section separator */}
        <div className="text-center py-16 flex flex-col items-center justify-center">
          <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            All Kenecare Services
          </p>
          <div
            className="w-16 h-1 bg-blue-500 mt-2"
            // data-aos="fade-right"
          ></div>
        </div>

        {/* Services Grid */}
        <div className="max-w-3xl mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {servicesData &&
            servicesData.map((service, index) => (
              // Individual service card
              <div
                key={service.title}
                className="bg-white overflow-hidden shadow rounded-lg"
                // data-aos="fade-up"
              >
                {/* Service Image */}
                <div className="relative flex w-full justify-center pt-3 h-48">
                  <img
                    src={service.img_file}
                    alt="Image Description"
                    height={200}
                    width={200}
                  />
                </div>

                {/* Service Title and Description */}
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <div className="ml-5 w-0 flex-1">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        {service.title}
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        {service.description}
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className='w-full mb-3 ml-6 py-8'>
            <Link
                to="/know-more"
                className='font-bold text-white text-xl bg-blue-500 py-2 px-10
                text-center rounded mt-5 hover:bg-blue-400 mt-5'
                >
                Back
            </Link>
        </div>
      </div>
    </div>
  );
};

export default AllServicesPage;
