import React, { useState, useEffect } from 'react';
import Api from '../../Utils/Api';
import 'react-multi-carousel/lib/styles.css';
import cold from '../../images/home/cold.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 4000,
  cssEase: "linear",
  pauseOnHover: true,
  grabCursor: true,
  padding: "100px",

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const ConsultTopDoctors = () => {

  const [data, setTopDoctors] = useState([]);

  useEffect(() => {
    const fetchTopDoctors = async () => {
      try {
        const response = await Api.get('/common-symptoms');
        if (response.data.status === 0) {
          // Handle error if needed
        } else {
          // Slice the data to get only top 5 doctors
          setTopDoctors(response.data.data.slice(0, 5));
        }
      } catch (error) {
        // Handle error if the API request fails
        console.error('Error fetching top doctors:', error);
      }
    };

    fetchTopDoctors();
  }, []);


    return (
      <div className='flex justify-center items-center flex-col w-full lg:px-0 px-2'>
        <div className='lg:w-3/4 w-full py-4 max-w-7xl'>
          <h3 className='text-white text-3xl'>
            Consult top doctors online for any health concern
          </h3>
          <p className='text-white text-lg'>
            Private online consultations with verified doctors in all specialists
          </p>
        </div>
        <div className='lg:w-3/4 w-full pb-4 max-w-7xl'>
        <Slider {...settings} className='w-full flex flex-col justify-center'>
            {data.map((item, index) => (
              <div key={index} className='w-10 flex flex-col items-center py-0 justify-center rounded-lg
                hover:shadow-lg hover:border-b h-[10rem] bg-white'
              >
                <img
                  src={item.imageUrl || cold}
                  alt={item.name}
                  className='rounded h-20  w-full object-cover object-center'
                />
                <div className='flex flex-col w-full justify-center items-center'>
                <h3 className='text-white text-xs font-bold text-center w-full max-w-[14rem] mt-3'>
                    {item.name}
                </h3>
                  <a href='https://app.kenecare.com/' target='_blank' alt='link' className='text-blue-400 text-center hover:text-blue-500 font-bold' rel="noreferrer">
                    CONSULT NOW
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  };

  export default ConsultTopDoctors;

