import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Nav } from 'react-bootstrap';
import { getUser, removeUserSession, parseJwt, getAuthToken } from '../Utils/Common';

function Header() {
  const navigate = useNavigate();

  const logout = () => {
    removeUserSession();
    navigate("/");
  }

  useEffect(() => {
    const user = getUser();

    if (user !== null) {
      const decodedJwt = parseJwt(getAuthToken());
      if (decodedJwt.exp * 1000 < Date.now()) {
        logout();
      }
    }
  }, []);


  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" id="kenav" className="flex w-full bg-white sm:fixed z-10">
      <Container className="gap-2 max-w-8xl">
            <Navbar.Brand href="/">
              <img src="assets/images/logo.png" alt="logo" className="w-16" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav" className="flex justify-center items-center w-full">

          <Nav className="flex justify-center items-center w-full">
            <Nav.Link href="/" className="border-l border-solid border-gray-300 px-4">
                <h5>Kenecare Home</h5>
                <p>Kenecare home page</p>
            </Nav.Link>

            <Nav.Link href="/know-more" className="border-l border-r border-solid border-gray-300 px-4">
                <h5>About Kenecare</h5>
                <p>Read about Kenecare</p>
            </Nav.Link>

            <Nav.Link href="/find-doctor" className="px-4">
                <h5>Find Doctors</h5>
                <p>Book an appointment</p>
            </Nav.Link>

            <Nav.Link href="/book-appointment" className="border-l border-r border-solid border-gray-300 px-4">
                <h5>Book an Appointment</h5>
                <p>Consult top doctors</p>
            </Nav.Link>

            <Nav.Link href="https://app.kenecare.com/" target="_blank" className="nav-btn ml-3 bg-blue-500 hover:bg-blue-400 px-4">
                Sign In / Signup
            </Nav.Link>
        </Nav>


          </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

