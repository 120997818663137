import CryptoJS from "crypto-js";
// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("auth_token");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
};

// Encrypt
export const encrypt = (string) => {
  var encStr = CryptoJS.AES.encrypt(string, "kenecare_JDI").toString();
  let encrypted_string = encStr
    .replace("+", "xMl3Jk")
    .replace("/", "Por21Ld")
    .replace("=", "Ml32");
  return encrypted_string;
};

// Encrypt
export const decrypt = (cipherText) => {
  cipherText = cipherText
    .toString()
    .replace("xMl3Jk", "+")
    .replace("Por21Ld", "/")
    .replace("Ml32", "=");
  var bytes = CryptoJS.AES.decrypt(cipherText, "kenecare_JDI");
  var decrypted_string = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted_string;
};

export const addMinutes = (time, minutes, type) => {
  if (type === "tomorrow") {
    const today = new Date();
    var fullDate = new Date();
    fullDate.setDate(today.getDate() + 1);
  } else {
    fullDate = new Date();
  }
  var dd = String(fullDate.getDate()).padStart(2, "0");
  var mm = String(fullDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = fullDate.getFullYear();

  fullDate = dd + "/" + mm + "/" + yyyy;

  var date = new Date(
    new Date(fullDate + " " + time).getTime() + minutes * 60000
  );
  var tempTime =
    (date.getHours().toString().length === 1
      ? "0" + date.getHours()
      : date.getHours()) +
    ":" +
    (date.getMinutes().toString().length === 1
      ? "0" + date.getMinutes()
      : date.getMinutes()) +
    ":" +
    (date.getSeconds().toString().length === 1
      ? "0" + date.getSeconds()
      : date.getSeconds());
  return tempTime;
};

export const getTimeSlots = (
  morning_start_time,
  morning_end_time,
  evening_start_time,
  evening_end_time,
  type
) => {
  var interval = "60";
  var mStartTime = morning_start_time.toString();
  var mEndTime = morning_end_time.toString();

  var eStartTime = evening_start_time.toString();
  var eEndTime = evening_end_time.toString();

  var morning_time_slots = [mStartTime];
  while (mStartTime !== mEndTime) {
    mStartTime = addMinutes(mStartTime, interval, type);
    if (mStartTime !== mEndTime) {
      morning_time_slots.push(mStartTime);
    }
  }

  var evening_time_slots = [eStartTime];
  while (eStartTime !== eEndTime) {
    eStartTime = addMinutes(eStartTime, interval, type);
    if (eStartTime !== eEndTime) {
      evening_time_slots.push(eStartTime);
    }
  }
  // console.log('Morning Time Slots: ', morning_time_slots);
  // console.log('Evening Time Slots: ', evening_time_slots);
  return {
    morning_time_slots: morning_time_slots,
    evening_time_slots: evening_time_slots,
  };
};

export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time.pop();
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  let formattedTime = time.join("");
  return formattedTime; // return adjusted time or original string
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const addDaysToCurrDate = (noOfDays, date) => {
  noOfDays = parseInt(noOfDays);
  if(date){
    var someDate = new Date(date);
  }else{
    someDate = new Date();
  }
  someDate.setDate(someDate.getDate() + noOfDays); //number  of days to add, e.x. 15 days
  var dateFormatted = someDate.toISOString().substr(0,10);
  return dateFormatted;
};

export const html_entity_encode = (str) => {
  var buf = [];
  for (var i=str.length-1;i>=0;i--) {
      buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
  }
  return buf.join('');
};

export const html_entity_decode = (str) => {
  return str.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
};

export const add_minutes = (dt, minutes) => {
  return new Date(dt.getTime() + minutes*60000);
};

export const currentDateTime = (format = '') => {
  const today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = String(today.getFullYear());
  var hh = String(today.getHours()).padStart(2, "0");
  var ii = String(today.getMinutes()).padStart(2, "0");
  var ss = String(today.getSeconds()).padStart(2, "0");

  var fullDate = yyyy + "-" + mm + "-" + dd + " " + hh + ":" + ii + ":" + ss;
  if(format !== ''){
    if(format === 'Y-m-d H:i'){
      fullDate = yyyy + "-" + mm + "-" + dd + " " + hh + ":" + ii;
    }else if(format === 'Y-m-d'){
      fullDate = yyyy + "-" + mm + "-" + dd;
    }else{
      fullDate = yyyy + "-" + mm + "-" + dd + " " + hh + ":" + ii + ":" + ss;
    }
  }
  return fullDate;
};

// set the auth token and user from the session storage
export const setAuthToken = (token) => {
  localStorage.setItem("auth_token", token);
};

// return the auth token from the session storage
export const getAuthToken = () => {
  return localStorage.getItem("auth_token") || null;
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const numberFormat = (currency, number) => {
  return currency+' ' + number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
};