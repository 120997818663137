import React from 'react'
import Header from '../Pages/header'
import Footer from '../Pages/footer'

const Terms = () => {
  return (
    <>
    <Header />

    <div className='flex flex-col w-full justify-center items-center bg-gray-100 py-10'>
        <div className='flex flex-col  justify-center items-center w-full max-w-7xl sm:mt-20'>
            <h1 className='text-2xl font-bold text-center text-gray-700'>
                Terms and Conditions for KeneCare Application
            </h1>

            <div className='w-full max-w-[50rem] text-justify mt-10 px-2'>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>Acceptance of Terms:</h3>
            <p className='text-lg text-gray-700'>
                By downloading, accessing, or using the KeneCare Mobile ("the App"), you agree to be bound by these Terms
                and Conditions. If you do not agree with any part of these terms, you may not use the App.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Description of Service:
            </h3>
            <p className='text-lg text-gray-700'>
                KeneCare Mobile provides a platform for patients to connect with healthcare professionals for video
                consulting services. The App facilitates remote consultations between patients and doctors, enabling
                users to seek medical advice, diagnoses, and treatment recommendations.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Eligibility:
            </h3>
            <p className='text-lg text-gray-700'>
                You must be at least 18 years old to use the App. By using the App, you represent and warrant that you
                are of legal age and capacity to enter into this agreement.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Registration and Account Security:
            </h3>
            <p className='text-lg text-gray-700'>
                To access certain features of the App, you may be required to create an account. You agree to provide
                accurate, current, and complete information during the registration process and to keep your account
                information updated. You are responsible for maintaining the confidentiality of your account credentials
                and for all activities that occur under your account.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                User Conduct:
            </h3>
            <p className='text-lg text-gray-700'>
                You agree to use the App only for lawful purposes and in accordance with these Terms and Conditions.
                You may not use the App in any manner that could damage, disable, overburden, or impair the
                functionality of the App or interfere with any other party's use and enjoyment of the App.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Consultation Fees:
            </h3>
            <p className='text-lg text-gray-700'>
                Users may be required to pay consultation fees for accessing video consulting services through the App.
                Consultation fees are determined by the healthcare professionals providing the services and are subject
                to change without notice. Payment for services must be made through the payment methods accepted by
                the App.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Medical Disclaimer:
            </h3>
            <p className='text-lg text-gray-700'>
                The information provided on the App is for informational purposes only and should not be considered
                medical advice, diagnosis, or treatment. Only the actual paid consultations with healthcare
                professionals through the App establish a doctor-patient relationship.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Privacy Policy:
            </h3>
            <p className='text-lg text-gray-700'>
                Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use,
                and disclose your personal information when you use the App.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Intellectual Property:
            </h3>
            <p className='text-lg text-gray-700'>
                All content and materials available through the App, including but not limited to text, graphics,
                logos, images, and software, are the property of KeneCare Mobile or its licensors and are protected by
                copyright, trademark, and other intellectual property laws.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Limitation of Liability:
            </h3>
            <p className='text-lg text-gray-700'>
                To the fullest extent permitted by law, KeneCare Mobile shall not be liable for any indirect,
                incidental, special, consequential, or punitive damages arising out of or relating to your use of the
                App, including but not limited to damages for lost profits, loss of data, or business interruption.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Governing Law:
            </h3>
            <p className='text-lg text-gray-700'>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of the
                Republic of Sierra Leone. Any dispute arising out of or relating to these terms shall be resolved
                exclusively in the courts of Sierra Leone.
            </p>

            <h3 className='text-xl font-semibold text-gray-700 my-2'>
                Modifications to Terms:
            </h3>
            <p className='text-lg text-gray-700'>
                KeneCare Mobile reserves the right to modify or amend these Terms and Conditions at any time without
                prior notice. Your continued use of the App following the posting of changes constitutes your
                acceptance of the modified terms.
            </p>

            <p className='text-lg text-gray-700 py-3'>
                If you have any questions or concerns about these Terms and Conditions, please contact us at
                imotechsolution@gmail.com  or call +232-79-972611
            </p>

            <p className='text-lg text-gray-700 py-3'>
                Effective Date: 1st March, 2024.  Last Updated: 1st March, 2024
            </p>

            </div>

        </div>
    </div>

    <Footer />
    </>

  )
}

export default Terms
