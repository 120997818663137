import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
    lat: 40.7128, // Replace with your actual latitude
    lng: -74.0060, // Replace with your actual longitude
  };

function DirectionMap() {
  return (
    <>
    <div className='mt-5 mb-3 ml-10'>
        <Link
            to='/know-more'
            className='text-xl text-white bg-blue-500
            hover:bg-blue-400 rounded px-5 py-2'
            >
            Back
        </Link>
    </div>

    <LoadScript googleMapsApiKey="AIzaSyBGWk_4Eb--QPVPl6yAn4NN_EsqbQnJLyk">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
      >
        <Marker position={center} icon={{ path: FaMapMarkerAlt }} />
      </GoogleMap>
    </LoadScript>

    </>
  );
}

export default DirectionMap;
