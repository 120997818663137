import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import doctor from "../../images/findDoctor/doctor.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Banner() {
  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const navigate = useNavigate();
  const [city, setCity] = useState([]);
  const [section1, setSection1] = useState([]);
  const [inputs, setInputs] = useState({ location: "", doctor_name: "" });
  const [suggestions, setSuggestions] = useState([]);



  useEffect(() => {
    document.title = `Find Doctors | Kenecare`;
    //    document.getElementById("bodywrap").className = "homemain";

    apiInstance
      .get("cities")
      .then((response) => {
        if (response.data.status === 0) {
          // Handle error if needed
        } else {
          // Set the city state with the data from the API
          setCity(response.data.data);
        }
      })
      .catch((error) => {
        // Handle error if the API request fails
      });
  }, []);

  const fetchDoctors = (locationId, doctorName) => {
    const apiUrl = `doctors?locationId=${locationId}&q=${doctorName}`;
    //axios
    apiInstance
      .get(apiUrl)
      .then((response) => {
        if (response.data.status === "success") {
          setSection1(response.data.data);
        } else {
          console.error("API request failed");
        }
      })
      .catch((error) => {
        console.error("API request error:", error);
      });
  };

  const handleSearchDoctor = (event) => {
    event.preventDefault();
    const { location, doctor_name } = inputs;
    fetchDoctors(location, doctor_name);
    navigate("/search-doctor?loc=home", { state: inputs });
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));

    // Fetch doctor name suggestions as the user types
    const { location, doctor_name } = inputs;
    const apiUrl = `${apiInstance}doctors?locationId=${location}&q=${doctor_name}`;
    console.log("API URL for suggestions:", apiUrl);
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.status === "success") {
          setSuggestions(response.data.data);
        } else {
          setSuggestions([]);
        }
      })
      .catch((error) => {
        console.error("API request error:", error);
        setSuggestions([]);
      });
  };
  // Add function to handle suggestion click
  const handleSuggestionClick = (doctor) => {
    setInputs((inputs) => ({
      ...inputs,
      doctor_name: doctor.firstName + " " + doctor.lastName,
    }));
    setSuggestions([]);
  };

  return (
    <>
      <section
        className="
            text-gray-800 w-full flex bg-resources_banner_bg
            items-center justify-center bg-blue-500 "
        id="home"
      >
        <div
          className="
            flex sm:flex-row justify-center p-2
            sm:py-10 lg:py-24 lg:px-8 lg:flex-row
            lg:justify-between pb-4 pt-10
            xl:ml-5 w-10/12 max-w-7xl flex-col-reverse mt-10
            "
        >
          <div
            className="
                    flex flex-col items-center justify-center sm:w-1/2
                    lg:max-w-xl xl:max-w-2xl lg:text-center
                    "
          >
            <h3 className="sm:text-6xl text-2xl text-white font-bold mt-2">
              YOUR HOME FOR HEALTH
            </h3>

            <h5 className="text-orange-500 text-xl text-center font-bold mt-2">
              Find a doctor and book an appointment online
            </h5>

            {/* <div className='bg-orange-500 w-28 h-1 mt-8' data-aos="fade-right"> </div> */}

            <Link
              to={"/search-for-doctors/"}
              className="text-white bg-blue-700 w-60 text-center py-2 mb-3 rounded hover:bg-blue-600 mt-3 uppercase"
            >
              search for a doctor
            </Link>

          </div>

          <div
            className="
                    flex items-center justify-center p-6 mt-8
                    lg:max-w-6xl xl:max-w-6xl
                    lg:w-2/4
                    "
          >
            <img
              src={doctor}
              alt="Service"
              className="object-contain object-center h-full w-full"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
